import validate from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.3.1_en_e9343522275cf4f7783b3d9f2bf1404b/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45rules_45global from "/builds/rialtic/workflows/console-ui/apps/content/middleware/redirect-rules.global.ts";
import _01_45auth_45required_45global from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/middleware/auth-required.global.ts";
import manifest_45route_45rule from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.16.0_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.3.1_en_e9343522275cf4f7783b3d9f2bf1404b/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45rules_45global,
  _01_45auth_45required_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "is-content-admin": () => import("/builds/rialtic/workflows/console-ui/apps/content/middleware/is-content-admin.ts")
}