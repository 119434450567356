
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as edit_45type1T45CKsiowNI_45QgWHItMWCD9AwTdvP3aReiXa_Jnv9UMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/edit-type.vue?macro=true";
import { default as _91id_93b94Gj5fQ12iGJ_45FXiJ59JSVloxTA77xOifBtFesA1mQMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/engine/[id].vue?macro=true";
import { default as create8VVPdps4Ocit6wwoJTkfQi4FV_IeG_45th_rjLeZzDAAQMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/engine/create.vue?macro=true";
import { default as indexHfyjKte3JMiFbx6UlN_S0ZO8WGJ5NeKnBqPFFh9mAxYMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/engine/index.vue?macro=true";
import { default as engineIcaeqsb0EdV4td2HxWiXgY9mTQYm5krfmmvaT9dhBNQMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/engine.vue?macro=true";
import { default as index9oLPxPEaoTEZbtiaVfqzcxocASdm3Hj_455ZU0LW0xMZcMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/index.vue?macro=true";
import { default as claim_45types_45historynfWk_45kVjCLSzwcYldXtt7f6t5glk8vLx6Q8K2GC72woMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/claim-types-history.vue?macro=true";
import { default as engineszqWrTPjcRuHk3IRda04XaqegW8y_qtdvqkJnU_45PYiyAMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/engines.vue?macro=true";
import { default as indexoQXdR7Dp56uh9zksFiBzbC5dP1SGYpRTWakT_ivt1mEMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/index.vue?macro=true";
import { default as indexwgeAyZFE8Z_45AF4gRzrqp3U389fwDb8jRpgyJu3oQ12cMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/reference-document/index.vue?macro=true";
import { default as _91id_93lw8Kvy_45bXAWUBgtvyw3QkEnODi_LV0JulF4mhQ35MrQMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id].vue?macro=true";
import { default as index3xbJkiOdZL9ZWcg_45hVUP5HuQRTRRj9LpIIKgnDFcE8YMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/index.vue?macro=true";
import { default as policy_45editorGNCIG4Wzvgzdk7rYsLtVO_45xUB0ezVflflPHaiJXbrj0Meta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor.vue?macro=true";
import { default as indexUMlp7_T9guGdy1nytme8LDmJ1aRzXMTtaUAZIz98rVIMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/index.vue?macro=true";
import { default as edit_8J7Nl4HDCE9NlBSy98p9KF6pG_45msw0PRALZvZL_jjoMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/reference-document/[docId]/edit.vue?macro=true";
import { default as add2Zuv3POLsz0IPAO7hOjV9_45c66AGhhgkRRA8spQOlE8QMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/reference-document/add.vue?macro=true";
import { default as createiErPXKuSAYG7kEF6r5T65QwW9Zs6MD_D0VMp_45DyAnUAMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy/create.vue?macro=true";
import { default as indexOYHIg8Rg5xdNLSx4R9xQj2gCM7qg18ZmpGpyFm7xM38Meta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/policy/index.vue?macro=true";
import { default as _91id_93nxfZzxhjw6Mqjg7oRGyVbwWyAP_45M3XPU3G_9SuXKJ4AMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/provider/[id].vue?macro=true";
import { default as createYfMNPRMg_45PQ9Kwv3zBa9kz9JX71zS6w2LkDrz_458R9HoMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/provider/create.vue?macro=true";
import { default as indexwOT9yaSDJmCMeH4HNRipuJ6SRwiObVuvaJnlGtrOpAIMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/provider/index.vue?macro=true";
import { default as providerEOsbEwtWXBTxgg5s_45RcxvBgZQCUT2MHHS4WVNsvNLa8Meta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/provider.vue?macro=true";
import { default as regionZF5eHWQbv1gaeiVB2CUBMsZskEmuy2VBr4JetkMQfQQMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/region.vue?macro=true";
import { default as source_45documentr7QuVTOQ7a_U8QpvfzmnQv3mdFZc_458PPbb_0TrpcITYMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/source-document.vue?macro=true";
import { default as source_45type_45level_4516bhvxOFR1J2RQpRWYrUozdb_gHBimfztE9d95AMJQ_45wMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/source-type-level-1.vue?macro=true";
import { default as source_45type_45level_452iOrcpqEKXS1mczHLJUpogq203cMiesX_45ZoKAeTeTzKcMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/source-type-level-2.vue?macro=true";
import { default as _91id_93yeTHxMQ7EJdp2S7_45rHJ0qoqnFpMklqYXuK1HuqY6fZEMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/topic/[id].vue?macro=true";
import { default as createmTYnVqBxzx27gVleciYeBFquzEAc6PW_45HwHrhtinIQgMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/topic/create.vue?macro=true";
import { default as indexu9Wy4qEItEDzuALBgTVLHvBhEenQB7PSStGwD4onPhQMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/topic/index.vue?macro=true";
import { default as topicKf_Ynriqbyo5nxwBVc7ZnOg1IZJoPKww40Vp9gayk6YMeta } from "/builds/rialtic/workflows/console-ui/apps/content/pages/topic.vue?macro=true";
import { default as auth_45callbackEjVGi_328R450UGPBHL7gKxgGlePcBkSoCYV3Rd6AEAMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45loginektrxvC9NpudrdnB5U5wJtROq8hU5l0UajxzXe_45b5lAMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statusrX_Dpd_45iSI_FiKI_ZqylUrWR_45z_45mL5fHdtiX3WOE56QMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
export default [
  {
    name: "edit-type",
    path: "/edit-type",
    meta: edit_45type1T45CKsiowNI_45QgWHItMWCD9AwTdvP3aReiXa_Jnv9UMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/edit-type.vue")
  },
  {
    name: engineIcaeqsb0EdV4td2HxWiXgY9mTQYm5krfmmvaT9dhBNQMeta?.name,
    path: "/engine",
    meta: engineIcaeqsb0EdV4td2HxWiXgY9mTQYm5krfmmvaT9dhBNQMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/engine.vue"),
    children: [
  {
    name: "engine-id",
    path: ":id()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/engine/[id].vue")
  },
  {
    name: "engine-create",
    path: "create",
    meta: create8VVPdps4Ocit6wwoJTkfQi4FV_IeG_45th_rjLeZzDAAQMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/engine/create.vue")
  },
  {
    name: "engine",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/engine/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/index.vue")
  },
  {
    name: policy_45editorGNCIG4Wzvgzdk7rYsLtVO_45xUB0ezVflflPHaiJXbrj0Meta?.name,
    path: "/policy-editor",
    meta: policy_45editorGNCIG4Wzvgzdk7rYsLtVO_45xUB0ezVflflPHaiJXbrj0Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor.vue"),
    children: [
  {
    name: _91id_93lw8Kvy_45bXAWUBgtvyw3QkEnODi_LV0JulF4mhQ35MrQMeta?.name,
    path: ":id()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id].vue"),
    children: [
  {
    name: "policy-editor-id-claim-types-history",
    path: "claim-types-history",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/claim-types-history.vue")
  },
  {
    name: "policy-editor-id-engines",
    path: "engines",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/engines.vue")
  },
  {
    name: "policy-editor-id",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/index.vue")
  },
  {
    name: "policy-editor-id-reference-document",
    path: "reference-document",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/[id]/reference-document/index.vue")
  }
]
  },
  {
    name: "policy-editor",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy-editor/index.vue")
  }
]
  },
  {
    name: "policy-id",
    path: "/policy/:id()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/index.vue")
  },
  {
    name: "policy-id-reference-document-docId-edit",
    path: "/policy/:id()/reference-document/:docId()/edit",
    meta: edit_8J7Nl4HDCE9NlBSy98p9KF6pG_45msw0PRALZvZL_jjoMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/reference-document/[docId]/edit.vue")
  },
  {
    name: "policy-id-reference-document-add",
    path: "/policy/:id()/reference-document/add",
    meta: add2Zuv3POLsz0IPAO7hOjV9_45c66AGhhgkRRA8spQOlE8QMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy/[id]/reference-document/add.vue")
  },
  {
    name: "policy-create",
    path: "/policy/create",
    meta: createiErPXKuSAYG7kEF6r5T65QwW9Zs6MD_D0VMp_45DyAnUAMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy/create.vue")
  },
  {
    name: "policy",
    path: "/policy",
    meta: indexOYHIg8Rg5xdNLSx4R9xQj2gCM7qg18ZmpGpyFm7xM38Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/policy/index.vue")
  },
  {
    name: providerEOsbEwtWXBTxgg5s_45RcxvBgZQCUT2MHHS4WVNsvNLa8Meta?.name,
    path: "/provider",
    meta: providerEOsbEwtWXBTxgg5s_45RcxvBgZQCUT2MHHS4WVNsvNLa8Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/provider.vue"),
    children: [
  {
    name: "provider-id",
    path: ":id()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/provider/[id].vue")
  },
  {
    name: "provider-create",
    path: "create",
    meta: createYfMNPRMg_45PQ9Kwv3zBa9kz9JX71zS6w2LkDrz_458R9HoMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/provider/create.vue")
  },
  {
    name: "provider",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/provider/index.vue")
  }
]
  },
  {
    name: "region",
    path: "/region",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/region.vue")
  },
  {
    name: "source-document",
    path: "/source-document",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/source-document.vue")
  },
  {
    name: "source-type-level-1",
    path: "/source-type-level-1",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/source-type-level-1.vue")
  },
  {
    name: "source-type-level-2",
    path: "/source-type-level-2",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/source-type-level-2.vue")
  },
  {
    name: topicKf_Ynriqbyo5nxwBVc7ZnOg1IZJoPKww40Vp9gayk6YMeta?.name,
    path: "/topic",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/topic.vue"),
    children: [
  {
    name: "topic-id",
    path: ":id()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/topic/[id].vue")
  },
  {
    name: "topic-create",
    path: "create",
    meta: createmTYnVqBxzx27gVleciYeBFquzEAc6PW_45HwHrhtinIQgMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/topic/create.vue")
  },
  {
    name: "topic",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/content/pages/topic/index.vue")
  }
]
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: auth_45callbackEjVGi_328R450UGPBHL7gKxgGlePcBkSoCYV3Rd6AEAMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: auth_45loginektrxvC9NpudrdnB5U5wJtROq8hU5l0UajxzXe_45b5lAMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue")
  },
  {
    name: "auth-status",
    path: "/auth/status",
    meta: auth_45statusrX_Dpd_45iSI_FiKI_ZqylUrWR_45z_45mL5fHdtiX3WOE56QMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue")
  }
]